<template>
  <div>
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="main mt-8">
      <div class="loader m-auto"></div>
      <strong v-if="isSubmitted" class="m-auto mt-8">กำลังดำเนินการ กรุณารอสักครู่</strong>
    </div>

    <!-- Content -->
    <div v-else class="main">
      <form v-on:submit.prevent="submit" class="content">
        <!-- Header -->
        <div class="flex flex-row">
          <p class="project-header mb-4">{{ form.title }} <span v-if="multiple && exist">{{ !translate ? 'ครั้งที่' : 'No.' }} {{ $route.params.oid }}</span></p>
          <div v-if="role === 'STD'" v-on:click="translate = !translate" class="language-button">{{ !translate ? 'EN' : 'TH' }}</div>
        </div>
        <hr>

        <!-- Choose -->
        <div v-if="notShow" class="row ml-4">
          <input type="radio" v-model="isSendFile" value="true" @change="isSendFile = true; isForm = false" class="my-auto mr-4"><label>ส่งไฟล์เอกสาร (มีลายเซ็นอาจารย์ที่ปรึกษารับรอง)</label>
          <input type="radio" v-model="isForm" value="true" @change="isSendFile = false; isForm = true" class="my-auto ml-8 mr-4"><label>กรอกแบบฟอร์ม {{ form.title }}</label>
        </div>

        <!-- Warning -->
        <div v-if="!translate">
          <p v-if="editable && multiple" class="ml-4 mt-2 text-red-500">*แบบฟอร์มนี้สามารถส่งได้หลายครั้งตลอดภาคเรียน และแก้ไขได้ตลอดระยะเวลาจนกระทั่งถึงเวลาสิ้นสุดการส่งเอกสาร*</p>
          <p v-else-if="editable" class="ml-4 mt-2 text-red-500">*แบบฟอร์มนี้สามารถแก้ไขได้ตลอดระยะเวลาจนกระทั่งถึงเวลาสิ้นสุดการส่งเอกสาร*</p>
          <p v-else-if="!editable && multiple" class="ml-4 mt-2 text-red-500">*แบบฟอร์มนี้สามารถส่งได้หลายครั้งตลอดภาคเรียน ทว่าการส่งแต่ละครั้งจะไม่สามารถแก้ไขได้ กรุณาตรวจสอบความถูกต้องก่อนส่งเอกสาร*</p>
          <p v-else class="ml-4 mt-4 text-red-500">*แบบฟอร์มนี้สามารถส่งได้เพียงครั้งเดียว และไม่สามารถแก้ไขได้ กรุณาตรวจสอบความถูกต้องก่อนส่งเอกสาร*</p>
          <p v-if="isForm && !isVDO" class="ml-4 mt-4 text-red-500">*โปรดอ่านคู่มือการใช้งานเพื่อทำความเข้าใจระบบและตกลงกับอาจารย์ที่ปรึกษา/อาจารย์ที่ปรึกษาร่วมให้เรียบร้อยก่อน หากต้องการกรอกแบบฟอร์ม*</p>
          <p v-if="isForm && !isVDO" class="ml-4 mt-4 text-red-500"><strong>คำเตือน:</strong> สำหรับนักศึกษาที่เลือกวิธีการกรอกแบบฟอร์มในการส่งเอกสาร นักศึกษาควรจะสำรองเวลาสำหรับอาจารย์ที่ปรึกษา/อาจารย์ที่ปรึกษาร่วมทำการตรวจสอบความถูกต้องของข้อมูลที่กรอก หากอาจารย์ที่ปรึกษา/อาจารย์ที่ปรึกษาร่วมไม่ทำการตรวจสอบความถูกต้องของข้อมูลภายในเวลาสิ้นสุดการส่งเอกสาร ระบบจะตัดสินว่าโครงงานนี้ไม่ได้ทำการส่งเอกสารดังกล่าว (เบื้องต้นระบบจะทำการส่งอีเมลแจ้งเตือนไปยังอาจารย์ที่ปรึกษา/อาจารย์ที่ปรึกษาร่วมหลังจากนักศึกษาทำการส่งข้อมูล แต่นักศึกษาควรจะแจ้งอาจารย์ที่ปรึกษา/อาจารย์ที่ปรึกษาร่วมเพิ่มเติมอีกครั้ง เนื่องจากมีโอกาสที่อีเมลจะถูกจัดเป็นอีเมลสแปมและไม่ถูกพบเห็น)</p>
        </div>
        <div v-else>
          <p v-if="editable && multiple" class="ml-4 mt-2 text-red-500">*This form can be submitted multiple times throughout the semester and can be edited at anytime until the end of the submission deadline.*</p>
          <p v-else-if="editable" class="ml-4 mt-2 text-red-500">*This form can be edited at anytime until the end of the submission deadline.*</p>
          <p v-else-if="!editable && multiple" class="ml-4 mt-2 text-red-500">*This form can be submitted multiple times throughout the semester. However, each submission can't be edited. Please verify correctness before submitted.*</p>
          <p v-else class="ml-4 mt-4 text-red-500">*This form can only be submitted once and can't be edited. Please verify the correctness before submitted.*</p>
          <p v-if="isForm && !isVDO" class="ml-4 mt-4 text-red-500">*Please read the Carbon system manual to understand the system and agree with your advisor/co-advisor first.*</p>
          <p v-if="isForm && !isVDO" class="ml-4 mt-4 text-red-500"><strong>Warning:</strong> For students who choose this method to fill out this form to submitted. You should reserve time for the advisor/co-advisor to verify the correctness of the information you entered. If the advisor/co-advisor don't verify the correctness of the information by the end of the submission deadline, The system will judge that this project hasn't submitted such documents. (Initially, the system will send a notification email to the advisor/co-advisor after you submits the information. However, you should inform the advisor/co-advisor for additional information. This is because there is a chance that the email will be classified as spam and will not be seen.)</p>
        </div>

        <!-- Send file -->
        <div v-if="isSendFile" class="mt-4">
          <div class="form-div">
            <p class="label">{{ !translate ? 'ไฟล์เอกสาร' : 'Document file' }}</p>
            <div class="upload-button ml-48">
              <label id="file-name" class="mr-8">{{ !translate && file == null ? 'ยังไม่มีการอัปโหลดไฟล์' : translate && file == null ? 'The file has not been uploaded yet' : file_name }}</label>
              <label for="students" class="file-button">{{ !translate ? 'อัปโหลด' : 'Upload' }}</label>
              <input type="file" ref="students" id="students" name="students" accept=".pdf, .pptx, .jpg, .png" required @change="handleSendFileUpload($event)" class="w-0">
            </div>
          </div>
        </div>

        <!-- Fobi form -->
        <div v-if="isForm" class="mt-4">
          <div v-for="element in form.elements" :key="element.id" class="form-div">
            <p class="label col-span-2">{{ element.plugin_data.label }}</p>
            <div v-if="element.type === 'input' || element.type === 'text'" class="col-span-3 my-2">
              <input
                type="text"
                class="w-full"
                v-model="data[element.plugin_data.name]"
                :id="element.plugin_data.name"
                :name="element.plugin_data.name"
                :placeholder="element.plugin_data.placeholder"
                :maxlength="element.plugin_data.max_length"
                :required="element.plugin_data.required"
              />
              <p>{{ element.plugin_data.help_text }}</p>
            </div>

            <div v-else-if="element.type === 'textarea'" class="col-span-3 my-2">
              <textarea
                class="w-full p-2"
                rows="5"
                v-model="data[element.plugin_data.name]"
                :id="element.plugin_data.name"
                :name="element.plugin_data.name"
                :placeholder="element.plugin_data.placeholder"
                :maxlength="element.plugin_data.max_length"
                :required="element.plugin_data.required"
              ></textarea>
              <p>{{ element.plugin_data.help_text }}</p>
            </div>
          
            <div v-else-if="element.type === 'select'" class="col-span-3 my-2">
              <select
                :name="element.plugin_data.name"
                :id="element.plugin_data.name"
                class="w-full"
                v-model="data[element.plugin_data.name]"
              >
                <option v-for="choice in element.plugin_data.choices.split('\r\n')" :key="choice" :value="choice">{{ choice }}</option>
              </select>
              <p>{{ element.plugin_data.help_text }}</p>
            </div>

            <div v-else-if="element.type === 'checkbox_select_multiple'" class="col-span-4 my-2 mt-4 row">
              <div v-for="choice in element.plugin_data.choices.split('\r\n')" :key="choice">
                <input
                  type="checkbox"
                  :id="element.plugin_data.name + '_' + choice"
                  :value="choice.split(',')[0]"
                  v-model="data[element.plugin_data.name]"
                  class="align-middle"
                />
                <label v-if="choice.split(',').length === 1" class="mx-4 align-middle">{{ choice.split(",")[0] }}</label>
                <label v-else class="ml-4 mr-16 align-middle">{{ choice.split(",")[1] }}</label>
              </div>
              <p>{{ element.plugin_data.help_text }}</p>
            </div>

            <div v-else-if="element.type === 'date'" class="col-span-3 my-2">
              <input
                type="date"
                class="w-full"
                v-model="data[element.plugin_data.name]"
                :id="element.plugin_data.name"
                :name="element.plugin_data.name"
                :placeholder="element.plugin_data.placeholder"
                :maxlength="element.plugin_data.max_length"
                :required="element.plugin_data.required"
              />
              <p>{{ element.plugin_data.help_text }}</p>
            </div>
          </div>
        </div>
        <hr>
        <button type="submit" value="submit" class="submit-button" :disabled="isSubmitted">{{ !translate ? 'บันทึกข้อมูล' : 'Save' }}</button>

        <!-- Warning -->
        <div v-if="submitExpired" class="text-red-500 mt-4">{{ !translate ? 'ไม่สามารถส่งข้อมูลได้ เนื่องจากแบบฟอร์มนี้สิ้นสุดระยะเวลาการกรอกข้อมูลแล้ว' : 'Can\'t submit the data because this form has expired' }}</div>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"

export default {
  name: "FormEdit",
  components: {
    NavBar,
  },
  data() {
    return {
      form: {
        name: "",
        title: "",
        elements: [],
      },
      data: {},
      course: null,
      project: null,
      order: 0,
      multiple: false,
      exist: true,
      loading: true,
      isSubmitted: false,
      isSendFile: true,
      isForm: false,
      isVDO: false,
      notShow: false,
      file: null,
      expired: false,
      editable: false,
      translate: false,
      submitExpired: false,
      file_name: '',
      role: null
    }
  },
  async created() {
    this.role = this.$store.getters.payload.role
    const formId = this.$route.params.fid
    const orderId = (this.$route.params.oid) ? (this.$route.params.oid) - 1 : 0
    this.orderId = orderId
    const formTemplate = await this.$store.dispatch("getFormById", formId)
    this.form = formTemplate.form_entry_detail
    document.title = this.form.title + " | Carbon"
    this.course = await this.$store.dispatch("getCourseById", formTemplate.course)
    this.multiple = formTemplate.multiple
    let formData = await this.$store.dispatch("getFormData", {form_id: this.$route.params.fid, project_id: this.$route.params.pid, order_id: orderId}).catch((error) => (this.exist = error))
    if (this.multiple && this.$route.params.oid === undefined) {
      formData = false
    }
    this.exist = Boolean(formData)
    let data = formData === false ? {} : formData.data.saved_data_dict
    if (data === undefined) {
      data = {}
    }

    for (let id in this.form.elements) {
      const element = this.form.elements[id]
      if (!(element.plugin_data.name in data)) {
        data[element.plugin_data.name] = element.plugin_data.initial
      }

      // Convert to array if element is checkbox select multiple
      if ( element.type === "checkbox_select_multiple" && typeof data[element.plugin_data.name] === "string") {
        data[element.plugin_data.name] = data[element.plugin_data.name].split(",")
      }
    }
    
    // Check if this form only send file or form
    if (this.form.elements.length === 0) {
      this.notShow = false
      this.isSendFile = true
      this.isForm = false
    } else if (this.form.elements.length !== 0) {
      this.notShow = true
      this.isSendFile = true
      this.isForm = false
    }

    // BIT send file only
    if (this.course.program_code == "BIT") {
      this.notShow = false
      this.isSendFile = true
      this.isForm = false
    }

    // Check if this form editable
    if (this.form.success_page_title !== null) {
      if (this.form.success_page_title.split(',').includes('editable')) {
        this.editable = true
      }
      if (this.form.success_page_title.split(',').includes('vdo')) {
        this.isVDO = true
        this.notShow = false
        this.isSendFile = false
        this.isForm = true
      }
    }

    this.data = data
    this.project = await this.$store.dispatch("getProjectById", this.$route.params.pid)

    // Check if this form expired
    if (new Date(formTemplate['active_date_from']) > new Date() || new Date(formTemplate['active_date_to']) < new Date()) {
      this.expired = true
    } else {
      this.expired = false
    }

    // Permission
    if (formTemplate['role'] != this.role) {
      this.$router.push(`/project/${this.$route.params.pid}/`)
    } else {
      if (this.project.members.includes(this.$store.getters.payload.user_id) == false && formTemplate['role'] == 'STD' || this.expired) {
        this.$router.push("/")
      } else if (this.project.advisors.includes(this.$store.getters.payload.user_id) == false && formTemplate['role'] == 'TCH' || this.expired) {
        this.$router.push(`/project/${this.$route.params.pid}/`)
      } else if (this.exist && this.form.success_page_title === null && formData.status !== 'F') {
        this.$router.push(`/project/${this.$route.params.pid}/`)
      }
    }
    this.loading = false
  },
  methods: {
    handleSendFileUpload(event) {
      this.file_name = !this.translate ? "ยังไม่มีการอัปโหลดไฟล์" : "The file has not been uploaded yet"
      this.file = event.target.files[0]
      if (this.file == undefined) {
        this.file = null
      } else if (this.file !== undefined || this.file !== null) {
        this.file_name = event.target.files[0].name
      }
    },
    async submit() {
      const formTemplate = await this.$store.dispatch("getFormById", this.$route.params.fid)

      if (new Date(formTemplate['active_date_from']) > new Date() || new Date(formTemplate['active_date_to']) < new Date()) {
        this.submitExpired = true
      } else {
        this.submitExpired = false
      }
    
      if (!this.isSubmitted) {
        this.isSubmitted = true
        this.loading = true
        if (!this.submitExpired) {
          // Send file
          if (this.isSendFile && this.file != null) {
            const data = { form_id: this.$route.params.fid, project_id: this.$route.params.pid, order_id: this.orderId, file: this.file, exist: this.exist }
            const success = await this.$store.dispatch('updateFileFormData', data)
            if (success && this.multiple) {
              this.$router.push(`/project/${this.$route.params.pid}/`)
            } else if (success) {
              this.$router.push(`/form/${this.$route.params.fid}/project/${this.$route.params.pid}/`)
            }
          }

          // Fobi form
          else {
            const data = { form_id: this.$route.params.fid, project_id: this.$route.params.pid, order_id: this.orderId, raw_data: this.data, exist: this.exist }
            let success = false
            if (this.exist) {
              success = await this.$store.dispatch("updateFormData", data)
            } else {
              success = await this.$store.dispatch("createFormData", data)
            }
            
            if (success && this.multiple) {
              this.$router.push(`/project/${this.$route.params.pid}/`)
            } else if (success) {
              this.$router.push(`/form/${this.$route.params.fid}/project/${this.$route.params.pid}/`)
            }
          }
        }
        this.isSubmitted = false
        this.loading = false
      }
    },
  },
}
</script>